import './App.css';

function App() {
  return (
    <div>
      <div>
        <div
          className="rtl bg-gradient header-container "
          style={{ direction: 'rtl' }}
        >
          <div className="rtl container">
            <div className="rtl row">
              <div className="col-md-8 col-6">
                <ul className="header__menu">
                  <img
                    className="logo"
                    src="assets/image/footer-logo.png"
                    alt="logo"
                    style={{ height: '3rem', margin: 0, padding: 0 }}
                  />
                </ul>
                <div className="header">
                  {/* <div id="primary-menu-trigger">
                    <div id="mainMenu-trigger">
                      <div className="header-hamburger-btn hidden-lg-up ">
                        <a
                          href="#espritmenu"
                          className="mburger mburger--collapse"
                        >
                          <b />
                          <b />
                          <b />
                        </a>
                      </div>
                    </div>
                  </div> */}
                  {/* <nav className="es-navbar es-navbar-default" id="espritmenu">
                    <ul>
                      <li>
                        <a href="#">خانه</a>
                      </li>
                      <li>
                        <a href="#">درباره ما</a>
                      </li>
                      <li>
                        <a href="#">تماس با ما</a>
                      </li>
                    </ul>
                  </nav> */}
                </div>
              </div>
              <div className="col-md-4 col-6 header__button">
                <a
                  href="https://www.google.com/maps/place/Sharif+Innovation+Station"
                  className="button item"
                >
                  <span className="button__line" />
                  <span className="button__line" />
                  <span className="button__line" />
                  <span className="button__line" /> مدیریت دارایی
                </a>
              </div>
            </div>
          </div>
        </div>
        <article className="page-one bg-gradient " style={{ direction: 'rtl' }}>
          <div className="container">
            <div className="row r-top-order">
              <div
                className="col-md-6 col-12 box-content"
                style={{ direction: 'rtl', textAlign: 'right' }}
              >
                <img className="logo" src="assets/image/logo.png" alt="logo" />
                <h4
                  text="right"
                  className="description-text"
                  style={{
                    direction: 'rtl',
                    alignContent: 'rtl',
                    textAlign: 'right',
                  }}
                >
                  پلتفرم هوشمند جامعه محور پیشنهاد و برنامه ریزی مسیر تردد
                  روزانه در شهر دوستدار خانواده
                  <br />
                  مبتنی بر هوش مصنوعی غیرمتمرکز
                </h4>
                <a
                  className="o-btn btn-style"
                  href="https://www.google.com/maps/place/Sharif+Innovation+Station"
                  style={{ direction: 'rtl' }}
                >
                  افزودن محل دوستدار خانواده
                </a>
              </div>
              <div className="col-md-6 col-12 page-one__pic">
                <img
                  className="banner-img"
                  src="assets/image/f1-bg.png"
                  alt="disable people"
                />
              </div>
            </div>
          </div>
        </article>
        <section className="page-two box">
          <div className="container ">
            <div className="row">
              <div className="col-12">
                <h3 className="h2-title">
                  ویژگی پلتفرم مدیریت شهر دوستدار خانواده
                </h3>
              </div>
              <div className="  col-lg-3 col-sm-6 col-12 focuse__item ">
                <img className="item-img" src="assets/image/1 (1).png" />
                <p className="item-text">
                  - تایید و صحت سنجی اجتماع محور اماکن دوستدار خانواده
                </p>
              </div>
              <div className=" col-lg-3 col-sm-6 col-12 focuse__item ">
                <img className="item-img" src="assets/image/2.png" />
                <p className="item-text">
                  تسریع در توسعه فراگیر و مشارکتی معماری شهری
                </p>
              </div>
              <div className="  col-lg-3 col-sm-6 col-12 focuse__item ">
                <img className="item-img" src="assets/image/3.png" />
                <p className="item-text">
                  خلق زنجیره ارزش اقتصادی نوین در جوامع شهری
                </p>
              </div>
              <div className=" col-lg-3 col-sm-6 col-12 focuse__item ">
                <img className="item-img" src="assets/image/4.png" />
                <p className="item-text">
                  درگاه یکپارچه مشارکتهای اجتماعی شهری در جوامع پایدار
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gradient " style={{ direction: 'rtl' }}>
          <div className="container">
            <div className="row r-top-order">
              <div
                className="col-md-6 col-12 box-content"
                style={{ direction: 'rtl', textAlign: 'right' , marginTop: 2}}
              >
                <h2
                  text="right"
                  style={{
                    direction: 'rtl',
                    alignContent: 'rtl',
                    textAlign: 'right',
                    margin: '1rem 0 ',
                  }}
                >
                  گواهی سطح سازگاری
                </h2>
                <p className="coin__text" style={{ textAlign: 'right' }}>
                  گواهی سطح سازگاری یک سند معتبر، نشان دهنده سطح سازگاری محیطی و
                  اجتماعی اماکن و مسیرهای تردد برای دسترسی عادلانه به خدمات شهری
                </p>
              </div>
              <div
                className="col-md-6 col-12 page-one__pic"
                style={{ textAlign: 'left', direction: 'ltr' }}
              >
                <img
                  className="banner-img"
                  src="assets/image/eleven.png"
                  alt="tarh yazdahom"
                  style={{ borderRadius: '10%', width: 300, height: 300 }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="page-three coin box">
          <div className="container">
            <div className="row ">
              <div className="col-lg-6 col-12 coin__content">
                <h3 className="h2-title" style={{ textAlign: 'right' }}>
                  زنجیره اقتصادی " توکن سازگاری "
                </h3>
                <p className="coin__text" style={{ textAlign: 'right' }}>
                  شکل گیری " شبکه دوستدار خانواده "، موجبات خلق یک زنجیره
                  اقتصادی نوظهور (‌اقتصاد توکنی‌) و یک ابزار مالی (‌توکن‌) در
                  ابتکار E-Wheel می شود. ابزار مالی ایجاد شده در این زنجیره
                  اقتصادی، با هدف " خلق ارزش اشتراکی " در شبکه ذینفعان برای
                  داشتن انگیزه توسعه و فعالیت در آن است. ابزار مالی ایجاده شده
                  (توکن) در شبکه زنجیره اقتصادی را " توکن سازگاری " می نامیم.
                  <br />
                  بررسی‌ها نشان میدهد که لازمه فراگیری یک ابزار مالی (‌توکن‌) در
                  جوامع پایدار و همچنین ایجاد " زنجیره ارزش اقتصادی " توجه به
                  اصول پایه‌ی نظام اقتصادی است. در این خصوص، یکی از مهمترین اصول
                  نظام‌های اقتصادی، اصل نقدشوندگی ابزار مالی (‌توکن‌) در زنجیره
                  ارزش می باشد. رویکرد ما برای حل این مسئله؛ ایجاد " اکوسیستم
                  زنجیره اقتصادی توکن‌ سازگاری " بعنوان یک فرصت ارزش آفرین است.
                </p>
                <div className="style-btn__container">
                  <a className="o-btn btn-style" href="#">
                    وایت پیپر
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-12 blockchain-video__container">
                <video autoPlay loop muted>
                  <source src="assets/video/rp.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </section>
        <section className="box z" style={{ minHeight: 300 }}>
          <div className="container">
            <div className="row" style={{ flexDirection: 'row-reverse' }}>
              <div
                className="col-lg-6 col-12 coin__content"
                style={{ direction: 'rtl', textAlign: 'right' }}
              >
                <h2
                  text="right"
                  style={{
                    direction: 'rtl',
                    alignContent: 'rtl',
                    textAlign: 'right',
                    margin: '1rem 0 ',
                  }}
                >
                  عرضه اولیه و نقدینگی در بازار ثانویه
                </h2>
                <p className="coin__text" style={{ textAlign: 'right' }}>
                  عرضه توکن های سازگاری در بازار اولیه خصوصی سبب تبادل این ابزار
                  مالی در زنجیره اقتصادی شده و مازاد آن در بازارثانویه برای
                  افزایش نقدینگی و تکمیل زنجیره ارزش اقتصادی جهت داد و ستد عرضه
                  می شود. این روش سبب افزایش نقدینگی توکن های سازگاری با ورود
                  سرمایه های خرد و غیرمولد، در بازار می شود.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="page-four map">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3>شهر در دسترس خانــــــــواده</h3>
              </div>
              {/* <div className="col-12">
                <img className="map__img" src="assets/image/roadmap.png" />
              </div> */}
            </div>
          </div>
        </section>
        <section className="page-four team box ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="h2-title">تیم ما</h3>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 o-column">
                <img className="team__img" src="assets/image/bashiri.png" />
                <p className="team__lable">
                  Behboud BASHIRI
                  <br />
                  CEO
                </p>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 o-column">
                <img className="team__img" src="assets/image/behzad.png" />
                <p className="team__lable">
                  Behzad CHAHARBAGHI
                  <br />
                  CTO
                </p>
              </div>
              <div className="col-lg-3 col-sm-6 col-12  o-column">
                <img className="team__img" src="assets/image/ghazale.png" />
                <p className="team__lable">
                  Zahra DAVARZANI
                  <br />
                  Agile Coach
                </p>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 o-column">
                <img className="team__img" src="assets/image/darabi.jpg" />
                <p className="team__lable">
                  Amirhossein DARABI
                  <br />
                  CMO
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="page-five media box">
          <div className="container ">
            <div className="row">
              <div className="col-xl-12">
                <h3 className="h2-title">Media</h3>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 media__item">
                <a href="#">
                  <img className="item-img" src="assets/image/media.png" />
                  <p className="media-text-title">This is a news title</p>
                  <p className="media-text">This is a news description</p>
                </a>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 media__item">
                <a href="#">
                  <img className="item-img" src="assets/image/media.png" />
                  <p className="media-text-title">This is a news title</p>
                  <p className="media-text">This is a news description</p>
                </a>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 media__item">
                <a href="#">
                  <img className="item-img" src="assets/image/media.png" />
                  <p className="media-text-title">This is a news title</p>
                  <p className="media-text">This is a news description</p>
                  <p className="media-text">This is a news description</p>
                </a>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 media__item">
                <a href="#">
                  <img className="item-img" src="assets/image/media.png" />
                  <p className="media-text-title">This is a news title</p>
                  <p className="media-text">This is a news description</p>
                </a>
              </div>
            </div>
          </div>
        </section> */}
        <footer className="box bg-gradient">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 ">
                <a href="#">
                  <img
                    className="footer-logo"
                    src="assets/image/footer-logo.png"
                    alt="logo"
                  />
                </a>
                <p className="logo-text">
                  سعی کنیم باهم زندگی بهتری برای یکدیگر بسازیم
                </p>
                {/* <div className="social-media">
                  <a href>
                    <i className="icon-instagram" />
                  </a>
                  <a href>
                    <i className="icon-telegram" />
                  </a>
                  <a href>
                    <i className="icon-phone-call1" />
                  </a>
                  <a href>
                    <i className="icon-linkedin" />
                  </a>
                </div> */}
              </div>
              <div className="col-lg-4 col-md-6 col-12 ">
                <ul className="contact-us">
                  <li>
                    <p className="footer_title">تماس با ما</p>
                  </li>
                  <li>
                    <i className="icon-earth" />
                    Tehran, Iran
                  </li>
                  <li>
                    <i className="icon-phone-call" />
                    021-75035250
                  </li>
                  <li>
                    <i className="icon-email" />
                    info@e-wheel.me
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 d-none d-lg-flex ">
                <img src="assets/image/love.png" />
              </div>
            </div>
          </div>
        </footer>
        <article className="container-fluid">
          <div className="row">
            <div className="col-12 copy-right">
              Copyright 2020 by E-Wheel.me All Rights Reserved
            </div>
          </div>
        </article>
      </div>

      {/* 
<script src="assets/script/jquery-3.5.1.min.js"></script>
<script src="assets/script/bootstrap.min.js"></script>
<script src="assets/script/script.min.js"></script>
<script src="assets/script/script.js"></script>
<script>
    const button = document.querySelector('.button');

    button.addEventListener('mouseenter', (e) => {
        let x = e.clientX - e.target.offsetLeft;
        let y = e.clientY - e.target.offsetTop;

        const ripples = document.createElement('span');
        ripples.style.left = x + 'px';
        ripples.style.top = y + 'px';
        button.append(ripples);
        setTimeout(() => {
            ripples.remove()
        }, 1000);
    });



    function Vector2(x, y) {
        this.x = x;
        this.y = y;
    }

    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function Track(position) {
        this.position = position;

        this.draw = function(context) {
            context.beginPath();
            context.rect(this.position.x, this.position.y, 20, 20);
            context.fillStyle = '#ff7e1a';
            context.fill();
            context.lineWidth = 1;
            context.strokeStyle = '#111';
            context.stroke();
        }
    }
    var startTime;
    window.onload = function() {
        document.getElementById('myCanvas').width = document.getElementById('title').offsetWidth;
        document.getElementById('myCanvas').height = document.getElementById('title').offsetHeight;
        setTimeout(function() {
            startTime = (new Date()).getTime();
            animate(startTime);
        }, 1000);
    }
    var timer = 0;

    var totalLoopTime = 10000;
    window.requestAnimFrame = (function(callback) {
        return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame ||
            function(callback) {
                window.setTimeout(callback, 1000 / 60);
            };
    })();

    var path = [];
    var ttd = [];

    function animate(startTime) {
        if (ttd.length > 100)
            ttd.shift();
        var canvas = document.getElementById('myCanvas');
        var context = canvas.getContext('2d');

        // update
        timer = (new Date()).getTime() - startTime;
        if (path.length == 0)
            path = generateRandomPoints(canvas);
        else {
            var nexPoint = getNextCoordinates(startTime, totalLoopTime, path);
            ttd.push(new Track(nexPoint));
        }

        // clear
        context.clearRect(0, 0, canvas.width, canvas.height);

        // draw stuff
        for (var i = ttd.length - 1; i >= 0; i--) {
            ttd[i].draw(context);
        };
        // request new frame
        requestAnimFrame(function() {
            animate(startTime);
        });
    }





    function getTimer() {
        return timer;
    }

    function generateRandomPoints(context) {
        var resultingVector = [];
        for (var i = 0; i < 6; i++) {
            var x = getRandomInt(0, context.offsetWidth);
            var y = getRandomInt(0, context.offsetHeight);
            var currentPoint3D = new Vector2(x, y);
            resultingVector.push(currentPoint3D);
        }
        return resultingVector;
    }

    function getNextCoordinates(loopStartTime, totalLoopTime, path) {
        var resultingPoint = new Vector2();
        var passedTime = timer;
        //Total passed ratio
        var passedRatio = passedTime / totalLoopTime;
        var totalSegments = path.length / 3;

        var totalTimePerSegment = totalLoopTime / totalSegments;
        //So it can loop forever
        while (passedRatio > 1) {
            passedRatio -= 1;
        }
        //Let's find our current bezier curve segment number
        var currentSegment = Math.floor(passedRatio * totalSegments);
        var currentSegmentRatio = (passedTime - currentSegment * totalTimePerSegment) / totalTimePerSegment;
        //It can be optimized here
        while (currentSegmentRatio > 1) {
            currentSegmentRatio -= 1;
        }

        var startingIndex = currentSegment * 3;
        if (startingIndex == path.length)
            startingIndex = 0;
        //our four path vertices
        var point0 = path[startingIndex];
        var point1 = path[startingIndex + 1];
        var point2 = path[startingIndex + 2];

        //if it's a last segment, we need to "connect" to the first vertex
        if (startingIndex + 3 >= path.length) {
            var point3 = path[0];
        } else {
            point3 = path[startingIndex + 3];
        }
        //At last, we find our coordinates
        if (typeof point0 == "undefined") {
            console.log(startingIndex);
            console.log(loopStartTime);
            console.log(path);
        }
        var tempRatio = 1 - currentSegmentRatio;
        resultingPoint.x = tempRatio * tempRatio * tempRatio * point0.x + 3 * tempRatio * tempRatio * currentSegmentRatio * point1.x + 3 * tempRatio * currentSegmentRatio * currentSegmentRatio * point2.x + currentSegmentRatio * currentSegmentRatio * currentSegmentRatio * point3.x;
        resultingPoint.y = tempRatio * tempRatio * tempRatio * point0.y + 3 * tempRatio * tempRatio * currentSegmentRatio * point1.y + 3 * tempRatio * currentSegmentRatio * currentSegmentRatio * point2.y + currentSegmentRatio * currentSegmentRatio * currentSegmentRatio * point3.y;
        return resultingPoint;
    }
</script>
<script>
    if ($(document).width() < '991.98') {
        $('.mm-listview').removeClass('sm ');
        $('.mm-listview').removeClass('sm-blue');
        $('.mm-listview').removeClass('sm-rtl');
        $('.header__menu').hide();
    } else {
        $('#espritmenu').hide();
    }
</script> */}
    </div>
  );
}

export default App;
